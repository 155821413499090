import React, { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import 'react-quill/dist/quill.snow.css';
import "./scss/main.scss";
import configureStoreProd from "./redux/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { lazy } from 'react';
import { IoChatbubblesOutline } from "react-icons/io5";




const { persistor, store } = configureStoreProd();

const Loader=({className='',id=''})=>{
  return <>
  <div id={id} className={`loaderDiv ${className}`}>
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
  </>
}

function App() {
  const routes = [
    { url: "/",path:'Home'},
    { url: "/pricing",path:'pricing'},
    { url: "/termuse",path:'TermUse'},
    { url: "/about",path:'about'},
    { url: "/privacypolicy",path:'Privacypolicy'},
    { url: "/classes",path:'classes'},
    { url: "/faq",path:'faq'},
    { url: "/login",path:'auth/Login'},
    { url: "/signup",path:'auth/Signup'},
    { url: "/ordernow",path:'OrderNow'},
    { url: "/dashboard", path:'Dashboard'},
    { url: "*",path:'NotFoundPage' },
    { url: "/profile",path:'Profile' },
    { url: "/profile/:tab", path:'Settings' },
    { url: "/forgotpassword", path:'auth/Forgotpassword' },
    { url: "/resetpassword", path:'auth/Resetpassword' },
    { url: "/user",path:'Users' },
    { url: "/user/edit/:id", path:'Users/AddEdit' },
    { url: "/user/add", path:'Users/AddEdit' },
    { url: "/user/detail/:id",path:'Users/View' },
    { url: "/", element: <Navigate to="/login" /> },
    { url: "/chat", path:'Chat' },
    { url: "/content", path:'Content' },
    { url: "/content/detail/:slug",path:'Content/View' },
    { url: "/content/add",path:'Content/AddEdit'},
    { url: "/content/edit/:slug", path:'Content/AddEdit'},
    { url: "/customers", path:'GrooveGroup' },
    { url: "/customers/edit/:id",path:'GrooveGroup/AddEdit' },
    { url: "/customers/add", path:'GrooveGroup/AddEdit'},
    { url: "/customers/detail/:id",path:'GrooveGroup/View' },
    { url: "/assignment", path:'Assignment' },
    { url: "/assignment/edit/:id",path:'Assignment/AddEdit' },
    { url: "/assignment/add", path:'Assignment/AddEdit'},
    { url: "/assignment/detail/:id",path:'Assignment/View' },
    { url: "/contract", path:'Contract' },
    { url: "/contract/edit/:id",path:'Contract/AddEdit' },
    { url: "/contract/add", path:'Contract/AddEdit'},
    { url: "/contract/detail/:id",path:'Contract/View' },
    { url: "/counter-offers",path:'CounterOffer' },
    { url: "/counter-offers/detail/:id",path:'CounterOffer/View' },
    { url: "/blogs", path:'blogs/Blog' },
    { url: "/blogs/:id", path:'blogs/BlogDetail' },
    { url: "/contact",path:'Contact' },
    { url: "/all-assignment",path:'AllAssignment' },
    { url: "/transactions", path: "Transactions" },
    { url: "transactions/detail/:id", path:'Transactions/TransactionDetail' },
    { url: "/notifications", path: "Notifications" },
    { url: "/FeaturedAssigment/detail/:id",path:'FeaturedAssigment' },
  ];

  sessionStorage.clear();

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
        <Suspense fallback={<Loader />}>
      
          <Router>
            <Routes>
              {routes.map((itm) => {
                const Element = lazy(() => import(`./pages/${itm.path}`));
                return <Route path={itm.url} element={itm.path?<Element/>:itm?.element} />;
              })}
            </Routes> 
          </Router>
          </Suspense>
        </PersistGate>
      </Provider>
      <Loader className="d-none" id="loader" />
      <ToastContainer position="top-right" />
    </>
  );
}

export default App;
